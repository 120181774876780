<template>
  <div class="vuse-content-wrapper vuse-user-profile">
    <v-container fluid>
      <v-row no-gutters justify="end" align="center">
        <help class="help-breadcrumb" :helpId="1" />
      </v-row>
      <v-card color="neu-glow-inset with-radius">
        <cover :company="identity" />
        <v-row class="mx-0">
          <v-col cols="12" sm class="d-flex align-center ml-md-12">
            <h5 class="font-weight-bold text-h6 text-sm-h5 basil--text">
              {{ company.name }}
            </h5>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2" class="pa-0">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="primary"
              right
              dense
              class="vuse-neu-ele"
              grow
              height="40"
            >
              <v-tabs-slider color="primary" />
              <v-tab v-for="(item, index) in items" :key="index">
                {{ locale === 'es' ? item.nameEs : item.nameEn }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card>

      <v-sheet class="transparent profile-tab-item">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in items" :key="index" dark>
            <v-sheet class="mt-3 transparent">
              <keep-alive>
                <component
                  :is="item.code"
                  :key="index"
                  :company="company"
                  :phrase="phrase"
                />
              </keep-alive>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
      <v-sheet class="transparent" v-if="tab === 0">
        <help class="help--org-chart" :helpId="2" />
        <organization-chart
          class="organization-chart"
          :pan="true"
          :datasource="companiesData"
          @node-click="
            ''

          "
        >
          <template v-slot="{ nodeData }">
            <company-card-1
              @selectedCompany="handleSelectedCompany($event)"
              :company="nodeData"
              :currentCompany="company"
            />
          </template>
        </organization-chart>
        <v-btn
          absolute
          bottom
          right
          small
          icon
          class="ma-5"
          @click="fullScreenDialog = true"
          ><v-icon>mdi-fullscreen</v-icon></v-btn
        >
      </v-sheet>
      <v-dialog v-model="fullScreenDialog" height="80%" :width="orgChartDialogWidth">
        <v-card class="lighten-3">
          <organization-chart
            class="dialog organization-chart"
            :pan="true"
            :zoom="true"
            :zoomin-limit="3"
            :zoomout-limit="0.5"
            :datasource="companiesData"
            @node-click="
              ''

            "
          >
            <template v-slot="{ nodeData }">
              <company-card-1
                @selectedCompany="handleSelectedCompany($event)"
                :company="nodeData"
                :currentCompany="company"
              />
            </template>
          </organization-chart>
          <v-btn icon absolute top right @click="fullScreenDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" :width="mapCardDialogWidth">
        <map-card :item.sync="selectedCompany" :loading="loadingCardCompany" />
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import Cover from '@/components/CorporateStructure/Cover'
  import About from '@/components/CorporateStructure/AboutCompany'
  import CompanyCard1 from '@/components/CorporateStructure/CompanyCard1'
  import OrganizationChart from 'vue-organization-chart'
  import 'vue-organization-chart/dist/orgchart.css'
  import { decryptData } from '@/utils/encryption'
  import MapCard from '@/components/Cards/MapCard'

  export default {
    name: 'MyCompany',
    components: {
      Cover,
      About,
      OrganizationChart,
      CompanyCard1,
      MapCard,
    },
    data() {
      return {
        index: 1,
        dialog: false,
        loading: false,
        loadingCardCompany: false,
        tab: 0,
        fullScreenDialog: false,
        identity: {},
        items: [{ code: 'about', nameEs: 'Información', nameEn: 'About' }],
        companiesData: {},
        selectedCompany: {},
        phrase: {},
      }
    },
    watch: {
      company() {
        this.getCompanies()
      },
    },
    computed: {
      ...mapGetters(['locale', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      translatedItems() {
        return this.items.filter((item) => item.lang === this.locale)
      },
      orgChartDialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '80%'
      },
      mapCardDialogWidth() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return '95%'
        } else if (this.$vuetify.breakpoint.smOnly) {
          return '80%'
        } else {
          return '50%'
        }
      },
    },
    created() {
      this.getIdentity()
      this.getCompanies()
      this.getPhrase()
    },
    methods: {
      async getCompanies() {
        this.loading = true
        this.$axios
          .get('companies?with[]=children.children.children&level_id=1&type_id=1&limit=1')
          .then((response) => {
            if (response.data.length > 0) {
              this.companiesData = response.data[0]
            }
            this.loading = false
          })
      },
      getIdentity() {
        this.$axios
          .get('identities?limit=1&order=created_at&by=desc')
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.identity = response.data[0]
            } else {
              this.identity = {}
            }
          })
      },
      getPhrase() {
        this.$axios.get('phrases?order=created_at&by=desc').then((response) => {
          const phrase = response.data.data.find(
            (phrase) => phrase.date === new Date().toISOString().split('T')[0]
          )
          if (phrase === undefined) {
            this.phrase = response.data.data[0]
          } else {
            this.phrase = phrase
          }
        })
      },
      handleSelectedCompany(company) {
        this.selectedCompany = {}
        this.dialog = true
        this.loadingCardCompany = true
        this.$axios
          .get('companies/' + company.id + '?with[]=type&with[]=level')
          .then((response) => {
            this.selectedCompany = response.data
            this.loadingCardCompany = false
          })
      },
    },
  }
</script>
<style lang="scss">
  .organization-chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog.organization-chart {
    border: none;
  }
</style>

<template>
  <v-sheet class="neu-glow-inset mb-3">
    <v-toolbar dense flat color="transparent">
      <v-btn icon color="blue" class="mx-0">
        <v-icon>public</v-icon>
      </v-btn>
      <v-toolbar-title>Info</v-toolbar-title>

      <v-spacer />

      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-toolbar>
    <v-card-text v-if="company.description">
      <div class="text-body-2" v-text="company.description" />
    </v-card-text>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Nivel: </v-list-item-title>
        <v-list-item-subtitle>{{ company.level.name }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line v-if="company.type.length > 0">
      <v-list-item-content>
        <v-list-item-title>Formato de empresa: </v-list-item-title>
        <v-list-item-subtitle>{{ company.type.name }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Ubicación: </v-list-item-title>
        <v-list-item-subtitle>{{ company.address }}</v-list-item-subtitle>
        <span style="color: rgba(0, 0, 0, 0.6); line-height: 1.2; font-size: 0.875rem"
          >Coordenadas: {{ company.latitude }}, {{ company.longitude }}</span
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Correo electrónico: </v-list-item-title>
        <v-list-item-subtitle>{{ company.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Sitio Web: </v-list-item-title>
        <v-list-item-subtitle
          ><a :href="company.link" target="_blank">{{
            company.link
          }}</a></v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-sheet>
</template>
<script>
  export default {
    props: {
      company: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {}
    },
  }
</script>

<template>
  <v-sheet class="neu-glow-inset py-4 mb-4">
    <v-row class="ma-0" :key="index">
      <v-col cols="12" md="4">
        <horizontal-card
          icon="mdi-arrange-bring-to-front"
          :title="String(stats.modules)"
          subtitle="Módulos"
          mediaSize="50"
          color="neu-glow-inset-primary"
          iconColor="primary"
        />
      </v-col>
      <v-col cols="12" md="4">
        <horizontal-card
          icon="how_to_reg"
          :title="String(stats.users)"
          subtitle="Usuarios"
          mediaSize="50"
          color="neu-glow-inset-primary"
          iconColor="blue"
        />
      </v-col>
      <v-col cols="12" md="4">
        <horizontal-card
          icon="mdi-store"
          :title="String(stats.companies)"
          subtitle="Empresas asociadas"
          mediaSize="50"
          color="neu-glow-inset-primary"
          iconColor="pink"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
  import { mapGetters } from 'vuex'
  import HorizontalCard from '@/components/UI/Card/HorizontalCard'
  import { decryptData } from '@/utils/encryption'

  export default {
    components: { HorizontalCard },
    data() {
      return {
        index: 1,
        stats: { modules: 0, users: 0, companies: 0 },
      }
    },

    created() {
      this.getStats()
    },
    watch: {
      company() {
        this.getStats()
      },
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },

    methods: {
      getStats() {
        this.$axios
          .get('company-management/company-statistics/' + this.company.id)
          .then((response) => {
            this.stats = response.data
            this.index++
          })
      },
    },
  }
</script>
<style lang="scss">
  .neu-glow-inset-primary {
    height: 100%;
  }
</style>

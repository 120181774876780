<template>
  <v-row>
    <v-col cols="12" md="4" class="intro-section">
      <intro :company="company" />
      <!-- <v-skeleton-loader v-else class="mx-auto" style="height: 100%" type="image" /> -->
    </v-col>
    <v-col cols="12" md="8">
      <statistics />
      <simple-text v-if="phrase" :title="phrase.text ? phraseText : ''" :text="''" />
    </v-col>
  </v-row>
</template>

<script>
  import Statistics from '@/components/CorporateStructure/CompanyStatistics.vue'
  import SimpleText from '@/components/CorporateStructure/SimpleText'
  import Intro from '@/components/CorporateStructure/Intro'

  export default {
    props: {
      company: {
        type: Object,
        required: true,
      },
      phrase: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        overview: {
          title: 'overview',
          icon: 'info',
        },
      }
    },
    computed: {
      phraseText() {
        if (this.phrase && this.phrase.text) {
          return `<blockquote>${this.phrase.text}<p><small>Autor: ${this.phrase.author}</small></p></blockquote>`
        } else {
          return ''
        }
      },
    },
    components: {
      SimpleText,
      Intro,
      Statistics,
    },
    beforeDestroy() {},
  }
</script>
<style lang="scss">
  .intro-section .v-skeleton-loader__image {
    height: 100%;
  }
</style>
